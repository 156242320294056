import React, { Component } from 'react';
import Slider from 'react-slick';
import '../../../node_modules/slick-carousel/slick/slick.css';
import '../../../node_modules/slick-carousel/slick/slick-theme.css';
import TestimFamily from '../../images/testimfamily.png';

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
};

const dotsSettings = {
    dots: false,
    autoplay: true,
    infinite: true,
    speed: 5000,
    arrows: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    focusOnSelect: true,
};
class WhatParentsSay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nav1: null,
            nav2: null,
        };
    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2,
        });
    }

    render() {
        return (
            <section className="page-section bg-secondary text-white testimsec">
                <h2 className="yellowtxt">What Parents Say</h2>
                <div
                    id="myCarousel"
                    className="carousel slide"
                    data-ride="carousel"
                >
                    <Slider
                        asNavFor={this.state.nav2}
                        ref={slider => (this.slider1 = slider)}
                        {...dotsSettings}
                        className="carousel-indicators"
                    >
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                    </Slider>
                    <Slider
                        {...settings}
                        asNavFor={this.state.nav1}
                        ref={slider => (this.slider2 = slider)}
                        className="carousel-inner"
                    >
           {/*             <div className="item carousel-item active">
                            <p className="testimonial">
                                “We Rock The Spectrum is a blessing for our family! We are now able to play safely together without fear and find comfort in an environment in which we finally “belong” with many new friends. Gail has worked so hard to make sure each and every family is welcomed with open arms and loves each one like her own. We look forward to the days we get to spend there and all of the fun new events Gail has happening.”
                            </p>
                            <p className="overview">- Kristin Streit</p>
                        </div>  */}
                        <div className="item carousel-item">
                            <p className="testimonial">
                                “My son begs me everyday to go to we rock after school and on the weekends. It has something for every age and the owners are extremely accommodating. Highly recommend!!! Also great birthday parties!”
                            </p>
                            <p className="overview">- Jennifer Rothstein Liquerman</p>
                        </div>
              {/*          <div className="item carousel-item">
                            <p className="testimonial">
                                “What a wonderful warm experience we had today at we rock the spectrum Boca Raton! My son, who is 6, and has multiple complex diagnoses was welcomed brightly and enthusiastically by John, who could not have been more accommodating and understanding. My son had a wonderful time and is eager to return! I will definitely be taking him back soon!”
                            </p>
                            <p className="overview">
                                - Yorelle Haroush
                            </p>
                        </div>  */}

                        <div className="item carousel-item">
                            <p className="testimonial">
                                “Visited today for the first time. The owner was fantastic, she was involved and can tell has a lot of pride in her business. The equipment was very clean and awesomely unique, which is great because its a whole new world of leaning available. My daughter had a great time climbing, jumping, sliding, swinging, building and drawing; this place has it all. Highly recommend!"
                            </p>
                            <p className="overview">
                                - Leah Stalker
                            </p>
                        </div>

                        <div className="item carousel-item">
                            <p className="testimonial">
                                “We love going to We Rock The Spectrum. There isn’t another place like it! It’s a great place to go indoors when it is scorching outside and soooooo clean! The owner is the nicest woman you will ever meet…and she is always cleaning everything the kids touch. My daughter and I both love it and highly recommend!!"
                            </p>
                            <p className="overview">
                                - Arlie Froetschel
                            </p>
                        </div>
          {/*              <div className="item carousel-item">
                            <p className="testimonial">
                                I took my kids to play one afternoon. We were there for hours and they did not want to leave. The owner is a really special person. She was eager to talk to the kids and show them all the equipment and toys. Gail made sure that everyone was having a good time while maintaining the space. The play space is extremely clean. My kids had a blast and can’t wait to go back. Plus, the price is right. This is a great indoor activity on a hot summer day (or any day for that matter!)
                            </p>
                            <p className="overview">
                                - Bina Brickner-Asher
                            </p>
                        </div>   
						
						
						 <div className="item carousel-item">
                            <p className="testimonial">
                                “We spent over an hour doing some intense, fun activities that my twin 4 yr. old seekers couldn’t get enough of. It was extremely busy but Gail made sure everyone was happy and that everything went smoothly for both the kids and the parents. We will be back for sure!”
                            </p>
                            <p className="overview">
                                - Gina DeGesero
                            </p>
                        </div>   
						
						 <div className="item carousel-item">
                            <p className="testimonial">
                                “This place is awesome! We were looking for an indoor location, last minute, that would be functional for both baby and toddler in the west Boca area. We Rock The Spectrum worked out great! So many activities for the kids like arts and crafts, trampoline, mini kitchen, tons of toys and so much more. Gail is a wonderful lady and was walking around cleaning up the entire time we were there. This was a great experience for my sister and our little ones. We will definitely be going back again!”
                            </p>
                            <p className="overview">
                                - Katharine DeFalco Skolnick
                            </p>
                        </div>
					
						
						 <div className="item carousel-item">
                            <p className="testimonial">
                                “My boys (2 and 3 years old) and I love We Rock!!! There is lots for my busy boys to do and Gail keeps it very clean! The gym is the perfect size – I can easily manage two boys at once – something I cant say for many public places!! Extremely affordable as well!”
                            </p>
                            <p className="overview">
                                - Allison Karr Koslowski
                            </p>
                        </div>  */}
						
						 <div className="item carousel-item">
                            <p className="testimonial">
                                “Absolutely lovely, We Rock the Spectrum. It is one of my favorite places to take my two kids. Always clean, friendly staff, safe toys, and games. What an awesome play place for children of all ages. We celebrated my son’s second birthday here, parents and kids had fun!”
                            </p>
                            <p className="overview">
                                - Jill Katz
                            </p>
                        </div>
						
			{/*			 <div className="item carousel-item">
                            <p className="testimonial">
                                “We rock the spectrum /Boca raton has to be one of my family favorite spots to play. Phillip absolutely loves to come and play, and it does a great deal for him. To see him socialize and So happy with other kids means everything to me. John and Gail are hands down the most welcoming, good hearted , understanding people I know. They talked / treat us like any family member will do, love coming to see them. Great Job! Definitely stop by and be ready to have a good time”
                            </p>
                            <p className="overview">
                                - Samayoa Jessie
                            </p>
                        </div>    
						
						 <div className="item carousel-item">
                            <p className="testimonial">
                                “I want to thank John and his volunteers for an amazing day!! We couldn’t have been more pleased. You went over and beyond to make this a GREAT celebration for my son!”
                            </p>
                            <p className="overview">
                                - Kara Veronica Ruddy
                            </p>
                        </div>  
						
						 <div className="item carousel-item">
                            <p className="testimonial">
                                “A very special thank you to Gail and John Field, who have opened up their gym (and their hearts) to Mylah and Samantha. You guys always treat my girls like your own and we appreciate and love you so much! The birthday party was amazing today and all the kids had a fabulous time.”
                            </p>
                            <p className="overview">
                                - Holly Goon
                            </p>
                        </div>  */}
						
						<div className="item carousel-item">
                            <p className="testimonial">
                                “We just had my daughters 4th birthday party here. It was amazing!!!!!!!! We had kids ages 1-10 and they all said the had the best time. It was fun, safe , clean and so well organized. Thank you so much for allowing us to have the best party!!!”
                            </p>
                            <p className="overview">
                                - Tracy Maslow Francesco
                            </p>
                        </div>
						
			{/*			 <div className="item carousel-item">
                            <p className="testimonial">
                                “An inspirational family owned business we can bring our families too ! Love Gail & John. xo. Everytime I see you with a child on the spectrum or with needs you light up , today you were so great with that little boy. You’re like a ray of sunshine and hope for those kids. Keep doing what you’re doing! Your heart is always in the right place and that says a lot about you. Of course my kids love you too Gail!!!”
                            </p>
                            <p className="overview">
                                - Christine Allocca
                            </p>
                        </div>   */}
						
						 <div className="item carousel-item">
                            <p className="testimonial">
                                “Amazing place and family owners that are a blessing to the whole community:):). My son had such a fun time, and was welcomed and spoken to with such respect as his own person:)! He had an absolute blast on that superhuman trampoline, lol, and the puzzles, swings, and everything! Feeling so grateful to have a place where we can fit:):):)!”
                            </p>
                            <p className="overview">
                                - Patricia Lomando
                            </p>
                        </div>
			{/*			<div className="item carousel-item">
                            <p className="testimonial">
                                “This is absolutely the best place I’ve ever taken my daughter I met wonderful people with special needs children and typical children…love it, Gail is amazing!”
                            </p>
                            <p className="overview">
                                - Ali Berman
                            </p>
                        </div>   */}
						
						 <div className="item carousel-item">
                            <p className="testimonial">
                                “This place LITERALLY ROCKS!!!!! We are here about 2-3 x’s a week and my boys cannot get enough. My oldest son Ryan (3yo) is not on the spectrum, however he has Childhood Apraxia of Speech and Sensory Processing Disorder. This place is a great way for him to get the sensory input he needs, as well as work on his speech! Thank you from the bottom of my heart WE ROCK!”
                            </p>
                            <p className="overview">
                                - Lisa Wackenhut Shipp
                            </p>
                        </div>
						
			{/*			 <div className="item carousel-item">
                            <p className="testimonial">
                                “I came here today for the first time with my son, who is blind and has cerebral palsy. He loved it so much, he played until he fell asleep. John is a very animated manager and he interacts with the kids. He pushed my son in the swing while I ate my dinner. Whenever my son did anything I felt like I had to apologize for, he’d stop me, and say “This is the one place you don’t have to say I am sorry!” My son and I have found our new weekend spot!!”
                            </p>
                            <p className="overview">
                                - Lauren Incitar
                            </p>    
                        </div>    
						<div className="item carousel-item">
                            <p className="testimonial">
                                “WRTS is an awesome place to bring your children. The gym is clean, the staff is kind, energetic, engaging, and my kids absolutely love Gail, the owner! My 6 year old gets mad on days we don’t go. There is lots of sensory equipment, which is great for kids on the spectrum AND typical kids! The trampoline and zip line are a huge hit with my kids. Gail and John, you guys did Boca Raton and the surrounding area a great service by adding this gym! You rock!”
                            </p>
                            <p className="overview">
                                - Holly Rogers
                            </p>
                        </div>  */}
						
						 <div className="item carousel-item">
                            <p className="testimonial">
                                “What an awesome place. Took my son there for the first time today and I never saw him so happy. The owner is a compassionate, warm hearted person. Can’t wait to spread the word about this wonderful place.”
                            </p>
                            <p className="overview">
                                - Jessica Sanchez
                            </p>
                        </div>
                    </Slider>
                    <img
                        className="quoteimg"
                        src={TestimFamily}
                        alt="Testimonials family"
                    />
                </div>
            </section>
        );
    }
}

export default WhatParentsSay;
